import * as jQuery from "jquery";

import { HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UrlSerializer } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppRootComponent } from "./app-root.component";
import { VituExceptionHandlerService } from "./vitu-exception-handler.service";
import { LowerCaseUrlSerializer } from "@Shared2/lowercase-router-serializer.service";
import { RequestTracker, RequestTrackerInstance } from "@Shared2/http-interceptors/request-tracker";
import { AngularHttpInterceptorProvider } from "@Shared2/http-interceptors/angular-interceptor";
import { CacheInterceptorProvider } from "@Shared2/http-interceptors/cache-interceptor";
import { SessionIdInterceptorProvider } from "@Shared2/http-interceptors/session-id-interceptor";
import { SOLUTION, SolutionCode } from "./solution";
import { WindowRef } from "@Shared2/window-ref.service";

window["$"] = window["jQuery"] = jQuery;

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        NgSelectModule,
        FormsModule,
    ],
    declarations: [
        AppRootComponent,
    ],
    bootstrap: [
        AppRootComponent,
    ],
    providers: [
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },
        {
            provide: ErrorHandler,
            useClass: VituExceptionHandlerService,
        },
        {
            provide: SOLUTION,
            useFactory: (windowRef: WindowRef) => {

                const path = windowRef.nativeWindow.location.pathname;
                if (path.toLowerCase().indexOf("/container") === 0) return SolutionCode.LOGIN;
                if (path.toLowerCase().indexOf("/billing") === 0) return SolutionCode.BILLING;
                if (path.toLowerCase().indexOf("/blog") === 0) return SolutionCode.BLOG;
                if (path.toLowerCase().indexOf("/elt") === 0) return SolutionCode.ELT;
                if (path.toLowerCase().indexOf("/fleet") === 0) return SolutionCode.FLEET;
                if (path.toLowerCase().indexOf("/insurance") === 0) return SolutionCode.INSURANCE;
                if (path.toLowerCase().indexOf("/interstate") === 0) return SolutionCode.INTERSTATE;
                return null;
            },
            deps: [WindowRef],
        },
        {
            provide: RequestTracker,
            useValue: RequestTrackerInstance,
        },
        AngularHttpInterceptorProvider,
        CacheInterceptorProvider,
        SessionIdInterceptorProvider,
    ],
})
export class AppModule {}
