import { Injectable } from "@angular/core";
import { Subject, of } from "rxjs";
import { catchError, filter, mergeMap, tap } from "rxjs/operators";
import { environment } from "environments/environment";
import { debouncableBuffer } from "@Utils/RxJs/debouncable-buffer";
import { WindowRef } from "@Shared2/window-ref.service";
import { UiLogController } from "@Assistant/Controllers-Angular/UiLogController";
import { UiRecord } from "@Assistant/Log/UiRecord";
import { LogLevel } from "@Assistant/SystemTypes/Logging/LogLevel";

const DEFAULT_BUFFER_INTERVAL = 7_000;
const MAX_BUFFER_SIZE = 100;

type ServiceInfo = {
    username: string;
    terminalId: string;
    assignedStates: string[];
    sessionId: string;
};

@Injectable({ providedIn: "root" })
export class Logger {

    constructor(
        private windowRef: WindowRef,
        private uiLogController: UiLogController,
    ) {

        const { host } = windowRef.nativeWindow.location;
        this.loggingEnabled = environment.allowedCloudLogHosts.indexOf(host) > -1;

        this.configureRemoteLogStream();
    }

    private loggingEnabled: boolean;
    private logEventStream = new Subject<UiRecord>();
    private bufferInterval = DEFAULT_BUFFER_INTERVAL;

    private serviceInfo: ServiceInfo = {} as ServiceInfo;

    log({
        logLevel,
        eventName: name,
        payload,
    }: {
        logLevel: LogLevel;
        eventName: string;
        /* eslint-disable-next-line @typescript-eslint/ban-types */
        payload?: object;
    }) {

        const logRecord: UiRecord = {
            Level: logLevel,
            Event: {
                name,
                payload,
                serviceInfo: {
                    ...this.serviceInfo,
                    clientLocalDateTime: (new Date()).toISOString(),
                    url: this.windowRef.nativeWindow.location.pathname,
                },
            },
        };

        this.logEventStream.next(logRecord);
    }

    setServiceInfo(serviceInfo: ServiceInfo) {

        this.serviceInfo = serviceInfo;
    }

    private configureRemoteLogStream() {

        this.logEventStream.pipe(
            filter(() => this.loggingEnabled),
            debouncableBuffer(() => this.bufferInterval, MAX_BUFFER_SIZE),
            filter(events => events.length > 0),
            mergeMap(events => this.sendEvents(events)),
        ).subscribe();
    }

    private sendEvents(events: UiRecord[]) {

        return this.uiLogController.LogStructEvent(
            events,
            { silent: true, suppressErrorToast: true },
        ).pipe(
            tap(() => { this.bufferInterval = DEFAULT_BUFFER_INTERVAL; }),
            catchError(() => {

                this.bufferInterval *= 1.5;
                events.forEach(event => { this.logEventStream.next(event); });
                return of(null);
            }),
        );
    }
}
