import { DeepEqual } from "@Utils/Object/DeepEqual";
export type CacheKey = string;

type PrimaryKey = string | number;

interface ICacheService {

    has(primaryKey: PrimaryKey, extraKeys: any): boolean;
    get<T = any>(primaryKey: PrimaryKey, extraKeys: any): T;
    set(primaryKey: PrimaryKey, extraKeys: any, value: any): void;
    remove(primaryKey: PrimaryKey, extraKeys: any): void;
    clean(): void;
}

interface CacheItem {
    extraKeys: any;
    response: any;
}

class CacheService implements ICacheService {

    private cache: { [primaryKey: string]: CacheItem[] } = {};

    has(primaryKey: PrimaryKey, extraKeys: any) {

        return Object.hasOwnProperty.call(this.cache, primaryKey)
            && this.cache[primaryKey].findIndex(i => DeepEqual(i.extraKeys, extraKeys)) > -1;
    }

    get<T = any>(primaryKey: PrimaryKey, extraKeys: any): T {

        return this.cache[primaryKey]?.find(i => DeepEqual(i.extraKeys, extraKeys)).response;
    }

    set(primaryKey: PrimaryKey, extraKeys: any, value: any) {

        this.remove(primaryKey, extraKeys);
        if (!this.cache[primaryKey]) { this.cache[primaryKey] = []; }
        this.cache[primaryKey].unshift({ response: value, extraKeys });
    }

    remove(primaryKey: PrimaryKey, extraKeys: any) {

        if (this.cache[primaryKey] && extraKeys !== undefined) {

            this.cache[primaryKey] = this.cache[primaryKey].filter(i => !DeepEqual(i.extraKeys, extraKeys));
        }
        else if (this.cache[primaryKey] && extraKeys === undefined) {

            delete this.cache[primaryKey];
        }
    }

    clean() {

        this.cache = {};
    }
}

class MockCacheService implements ICacheService {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    has(primaryKey: PrimaryKey, extraKeys: any) { return false; }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    get<T = any>(primaryKey: PrimaryKey, extraKeys: any): T { return undefined; }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    set(primaryKey: PrimaryKey, extraKeys: any, value: any) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    remove(primaryKey: PrimaryKey, extraKeys: any) {}

    clean() {}
}

export function CreateCache() {

    return window["$$angularUnitTestsMode"]
        ? new MockCacheService()
        : new CacheService();
}
