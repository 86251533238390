import { NgModule } from "@angular/core";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { KickstartStartupModel } from "./kickstart-startup-model.service";
import { WaitForStartupModel } from "./wait-for-startup-model.service";
import { SetPageClass } from "./set-page-class.service";
import { DropRouteReuseStrategy } from "@Shared2/drop-route-reuse-strategy";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: "",
                pathMatch: "full",
                redirectTo: "container",
            },
            {
                path: "billing",
                loadChildren: () => import("./billing2/billing-cli-entry.module").then(imported => imported.BillingModule),
                canLoad: [KickstartStartupModel],
                canActivate: [WaitForStartupModel, SetPageClass],
            },
            {
                path: "blog",
                loadChildren: () => import("./blog2/blog-cli-entry.module").then(imported => imported.BlogModule),
                canLoad: [KickstartStartupModel],
                canActivate: [WaitForStartupModel, SetPageClass],
            },
            {
                path: "container",
                loadChildren: () => import("./Container2/login-cli-entry.module").then(imported => imported.LoginModule),
                canActivate: [SetPageClass],
            },
            {
                path: "elt",
                loadChildren: () => import("./elt2/elt-cli-entry.module").then(imported => imported.EltModule),
                canLoad: [KickstartStartupModel],
                canActivate: [WaitForStartupModel, SetPageClass],
            },
            {
                path: "fleet",
                loadChildren: () => import("./fleet2/fleet-cli-entry.module").then(imported => imported.FleetModule),
                canLoad: [KickstartStartupModel],
                canActivate: [WaitForStartupModel, SetPageClass],
            },
            {
                path: "insurance",
                loadChildren: () => import("./insurance2/insurance-cli-entry.module").then(imported => imported.InsuranceModule),
                canLoad: [KickstartStartupModel],
                canActivate: [WaitForStartupModel, SetPageClass],
            },
            {
                path: "interstate",
                loadChildren: () => import("./interstate2/interstate-cli-entry.module").then(imported => imported.InterstateModule),
                canLoad: [KickstartStartupModel],
                canActivate: [WaitForStartupModel, SetPageClass],
            },
        ]),
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: DropRouteReuseStrategy,
        },
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {}
