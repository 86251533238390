export enum InternalSolutionCode {
All = "All",
RETAIL = "RETAIL",
FLEET = "FLEET",
JUNK = "JUNK",
ELT = "ELT",
RENEWAL = "RENEWAL",
BILLING = "BILLING",
UTILS = "UTILS",
PROFILE = "PROFILE",
HELP = "HELP",
CONTAINER = "CONTAINER",
BLOG = "BLOG",
ADMIN = "ADMIN",
INSURANCE = "INSURANCE",
INTERSTATE = "INTERSTATE"
}
